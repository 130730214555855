import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { authActions } from "./auth";

import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

const baseURL = process.env.REACT_APP_HOST_URL;

const converted_launchdate = (launchdate_int) => {
	const d = new Date(launchdate_int);
	const month_list = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	const year = d.toLocaleString("default", { year: "numeric" });
	const month_numeric = d.toLocaleString("default", { month: "numeric" });
	const day = d.toLocaleString("default", { day: "2-digit" });
	const month = month_list[month_numeric-1];

	return day + "-" + month + "-" + year;
}

export const updateLaunchdate = createAsyncThunk(
	"main/updateLaunchdate",
	async ({ id, newLaunchdate }, { getState, dispatch }) => {
		const state = getState();

		const axiosInstance = axios.create({
			baseURL,
			headers: { Authorization: `Bearer ${state.auth.authTokens.access}` },
		});

		axiosInstance.interceptors.request.use(async (request) => {
			const user_access = jwt_decode(state.auth.authTokens.access);
			const accessIsExpired = dayjs.unix(user_access.exp).diff(dayjs()) < 1;

			if (!accessIsExpired) return request;

			try {
				const response = await axios.post(`${baseURL}/api/token/refresh/`, {
					refresh: state.auth.authTokens.refresh,
				});

				localStorage.setItem("authTokens", JSON.stringify(response.data));
				dispatch(authActions.setAuthTokens(response.data));
				dispatch(authActions.setUser(jwt_decode(response.data.access)));

				request.headers.Authorization = `Bearer ${response.data.access}`;

				return request;
			} catch (error) {
				throw new Error(error.message);
			}
		});

		try {
			const response = await axiosInstance.put(`${baseURL}/api/launchdate/${id}/`, {
				launchdate: converted_launchdate(newLaunchdate),
			});
			return await response.data;
		} catch (error) {
			throw new Error("Unable to update launch date!");
		}
	}
);

export const getLaunchdate = createAsyncThunk(
	"main/getLaunchdate",
	async (arg, { getState, dispatch }) => {
		const state = getState();

		try {
			const response = await axios.get(`${baseURL}/api/launchdate/`);
			return await response.data;
		} catch (error) {
			throw new Error("Unable to retrieve current launch date!");
		}
	}
);

const initialMainState = {
	launchdate: null,
	id: 0,
	isLoading: true,
	updated: false,
	error: null,
};

const mainSlice = createSlice({
	name: "main",
	initialState: initialMainState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getLaunchdate.fulfilled, (state, action) => {
				state.launchdate = +new Date(action.payload[0].launchdate);
				state.id = action.payload[0].id;
				state.isLoading = false;
				state.updated = false;
				state.error = null;
			})
			.addCase(getLaunchdate.pending, (state) => {
				state.isLoading = true;
				state.updated = false;
				state.error = null;
			})
			.addCase(getLaunchdate.rejected, (state, action) => {
				state.isLoading = false;
				state.updated = false;
				state.error = action.error.message;
			})
			.addCase(updateLaunchdate.fulfilled, (state, action) => {
				state.launchdate = +new Date(action.payload.launchdate);
				state.id = action.payload.id;
				state.isLoading = false;
				state.updated = true;
				state.error = null;
			})
			.addCase(updateLaunchdate.pending, (state) => {
				state.isLoading = true;
				state.updated = false;
				state.error = null;
			})
			.addCase(updateLaunchdate.rejected, (state, action) => {
				state.isLoading = false;
				state.updated = false;
				state.error = action.error.message;
			});
	},
});

export const mainActions = mainSlice.actions;

export default mainSlice.reducer;
